import { graphql } from 'react-relay';
import Util from '../../../forecast-app/shared/util/util';
import * as tracking from '../../../tracking';
import { trackAndCommitMutation } from '../../../tracking/amplitude/TrackMutation';
const mutation = graphql `
	mutation updateSavedReportMutation($input: UpdateSavedReportInput!) {
		updateSavedReport(input: $input) {
			savedReport {
				id
				updatedAt
				updatedBy {
					id
					fullName
				}
				type
				name
				person {
					id
					active
					email
					fullName
					profilePictureId
				}
				groupingOne
				groupingTwo
				groupingThree
				groupingFour
				eyeApplied
				filterApplied
				filters {
					field
					value
					operator
				}
				startDate
				endDate
				periodType
				periodDate
				reportService
				privateAccess
				state
			}
		}
	}
`;
function commit(environment, input, onSuccess, onError, uploadables) {
    const changes = [];
    for (const key of Object.keys(input)) {
        if (['id'].includes(key) || input[key] === undefined) {
            continue;
        }
        changes.push(key);
    }
    tracking.trackEvent('Saved Report Updated', { _Changed: changes });
    const variables = { input: Object.assign({ csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId() }, input) };
    return trackAndCommitMutation(environment, {
        mutation,
        variables,
        uploadables,
        configs: [],
        onCompleted: onSuccess,
        onError: onError,
    });
}
export default { commit };
