import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import {omit} from 'lodash';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation duplicateSavedReportMutation($input: DuplicateSavedReportInput!) {
		duplicateSavedReport(input: $input) {
			savedReport {
				node {
					id
					name
					type
					person {
						id
						fullName
						email
						profilePictureId
					}
					reportService
					shares(first: 100) @connection(key: "Report_shares", filters: []) {
						edges {
							node {
								id
								user {
									id
									email
								}
							}
						}
					}
					updatedAt
					privateAccess
					favorite
					state
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: input.companyId,
			connectionInfo: [
				{
					key: 'Company_allSavedReports',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'savedReport',
		},
	];
}

function commit(environment, input, onSuccess, onError, uploadables) {
	const cleanedInput = omit(input, ['companyId']);

	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...cleanedInput}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		uploadables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}
export default {commit};
